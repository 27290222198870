/* From Uiverse.io by gharsh11032000 */
.button {
  cursor: pointer;
  position: relative;
  padding: 10px 24px;
  font-size: 18px;
  color: rgb(193, 163, 98);
  border: 2px solid rgb(193, 163, 98);
  border-radius: 34px;
  background-color: transparent;
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.320, 1);
  overflow: hidden;
}

.button::before {
  content: '';
  position: absolute;
  inset: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: inherit;
  scale: 0;
  z-index: -1;
  background-color: rgb(193, 163, 98);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.button:hover::before {
  scale: 3;
}

.button:hover {
  color: #212121;
  scale: 1.1;
  box-shadow: 0 0px 20px rgba(193, 163, 98,0.4);
}

.button:active {
  scale: 1;
}

/* App container styling */
.app-container {
  background-color: #1D2433;
  color: #2566EB;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.header-text {
  font-weight: bold; /* Fett */
  font-size: 6rem; /* Größe (96px), kann nach Bedarf angepasst werden */
  margin-bottom: 1.5rem; /* Abstand nach unten (24px), kann nach Bedarf angepasst werden */
  text-align: center; /* Zentriert den Text */
  background: linear-gradient(to right, #2566EB, #2398EC);
  -webkit-background-clip: text; /* Hintergrund auf Text beschränken (Webkit-browsers) */
  -webkit-text-fill-color: transparent; /* Text transparent machen, damit der Verlauf sichtbar ist */
  background-clip: text; /* Hintergrund auf Text beschränken (alle Browser) */
  text-fill-color: transparent; /* Text transparent machen (alle Browser) */
}


/* Erfolgsbenachrichtigung */
.success {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 320px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  background: #EDFBD8;
  border-radius: 8px;
  box-shadow: 0px 0px 5px -3px #111;
}

.success__icon {
  width: 20px;
  height: 20px;
  transform: translateY(-2px);
  margin-right: 8px;
}

.success__icon path {
  fill: #84D65A;
}

.success__title {
  font-weight: 500;
  font-size: 14px;
  color: #2B641E;
}

.success__close {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: auto;
}

.success__close path {
  fill: #2B641E;
}

/* Gradient Text Style */
.gradient-text {
  background: linear-gradient(to right, #b11a1a, #e65c5c); /* Farbverlauf von Rot zu Rosa */
  -webkit-background-clip: text; /* Hintergrund auf Text beschränken (Webkit) */
  -webkit-text-fill-color: transparent; /* Textfarbe transparent machen */
  font-weight: bold; /* Fettgedruckter Text */
}
